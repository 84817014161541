import { TypeRedux } from './TypeRedux'

const initialState = {
  account: {
    apps: {},
    appAccess: {},
    avatar: '',
    channelCode: '',
    channelName: '',
    estate: [],
    fullName: '',
    menus: {},
    roleCode: '',
    roleName: '',
    sysTimeOut: 0,
    userId: '',
    username: '',
  },
}

const AccountRedux = (state = initialState, action) => {
  switch (action.type) {
    case TypeRedux.LOGIN:
      // console.log('dataLogin: ', action.payload)
      // console.log('dataLogin: ', JSON.stringify(action.payload))
      return {
        ...state,
        profile: action.payload.account,
      }
    default:
      return state
  }
}

export default AccountRedux
