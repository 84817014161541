import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getStore } from '../../helpers/HelperGlobal'
import useLogout from '../../hooks/useLogout'

const Logout = () => {
  const navigate = useNavigate()
  const { requestLogout } = useLogout()

  useEffect(() => {
    const account = getStore('userAccount')
    if (account === null) navigate('/login')
    else requestLogout()
  }, [requestLogout, navigate])

  return null
}

export default Logout
