import React, { Component } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './assets/scss/style.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { getStore } from './helpers/HelperGlobal'
import { ActionRedux } from './redux/ActionRedux'
import AuthenticatedRoute from './core/menu/AuthenticatedRoute'
import Logout from './pages/Login/Logout'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const AppLayout = React.lazy(() => import('./parts/layout/AppLayout'))

// Pages
const Login = React.lazy(() => import('./pages/Login/Login'))
const Page404 = React.lazy(() => import('./pages/Errors/Page404'))
const Page500 = React.lazy(() => import('./pages/Errors/Page500'))

class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object,
    component: PropTypes.elementType,
  }
  componentDidMount() {
    const account = getStore('userAccount')
    if (account !== null) {
      this.props.dispatch(ActionRedux.login(account))
    }
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/" name="Penalty" element={<Navigate to="/general-player" />} />
            <Route
              path="/*"
              element={
                <AuthenticatedRoute>
                  <AppLayout />
                </AuthenticatedRoute>
              }
            />
          </Routes>
        </React.Suspense>
      </HashRouter>
    )
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  account: PropTypes.object,
  component: PropTypes.elementType,
}

const mapStateToProps = (state) => {
  return {
    account: state.account.profile,
  }
}

export default connect(mapStateToProps)(App)
