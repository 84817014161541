import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/id'

export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  return window.localStorage.setItem(name, content)
}

export const getStore = (name) => {
  if (!name) return
  return JSON.parse(window.localStorage.getItem(name))
}

export const removeItem = (name) => {
  if (!name) return
  return window.localStorage.removeItem(name)
}

export const isValidEmail = (value) => {
  return !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value))
}

export const validateInputCode = (value, flag) => {
  const result = value.replace(/[^a-zA-Z0-9 ]/g, '')

  return flag === 'UPPERCASE' ? result.toUpperCase() : result
}

export const validateInputAddress = (value, flag) => {
  const result = value.replace(/[^a-zA-Z0-9.,# ]/g, '')

  return flag === 'UPPERCASE' ? result.toUpperCase() : result
}

export const validateInputCode2 = (value, flag) => {
  const result = value.replace(/[^a-zA-Z0-9_-]/g, '')

  return flag === 'UPPERCASE' ? result.toUpperCase() : result
}

export const validateInputCode3 = (value, flag) => {
  const result = value.replace(/[^a-zA-Z0-9 _-]/g, '')

  return flag === 'UPPERCASE' ? result.toUpperCase() : result
}

export const validateFilter = (value, flag) => {
  const result = value.replace(/[^a-zA-Z0-9_ -*@ -]/g, '')

  return flag === 'UPPERCASE' ? result.toUpperCase() : result
}

export const validateNumericFilter = (e) => {
  const { value } = e.target;
  if (value === "") return ""
  
  let number_string = value.replace(/[^\d]/g, "")

  return number_string
}

export const validateTphInput = (value) => {
  value = value.replace(/[^0-9-,.]/g, '')
  value = value.replace(/-+/g, '-')
  value = value.replace(/,+/g, ',')
  value = value.replace(/[.]+/g, '.')
  value = value.replace(/[,.-]+[,.-]/g, '')
  value = value.replace(/[.-]\d+[.-]/g, '')
  return value
}

const menuRecursive = (array, lvl, code) => {
  let data = []
  for (const key in array) {
    if (array[key].lvl === lvl && array[key].parent_menu_cd === code) {
      data.push(array[key])
    }
  }
  return data
}

export const generatorMenu = (data) => {
  let rows = []
  for (const key in data) {
    if (data[key].lvl === 0) {
      rows.push(data[key])
      var hasParent = menuRecursive(data, 1, data[key].cd)
      if (hasParent.length > 0) {
        for (const i in hasParent) {
          rows.push(hasParent[i])
          var hasChild = menuRecursive(data, 2, hasParent[i].cd)
          if (hasChild.length > 0) {
            for (const c in hasChild) {
              rows.push(hasChild[c])
            }
          }
        }
      }
    }
  }
  return rows
}

export const rupiahFormat = (value) => {
  if (!value) return '0'
  const nf = Intl.NumberFormat('id-ID', {
    currency: 'IDR',
    style: 'currency',
    maximumFractionDigits: 0,
  })

  return nf.format(value)
}

export const thousandFormat = (value) => {
  if (!value) return '0'
  const nf = Intl.NumberFormat('id-ID', {
  })

  return nf.format(value)
}

export const formatDecimal = (number,) => {
  if (!number) return number
  return number.toLocaleString(['id'])
}

export const calculateDuration = (start, end) => {
  if (!end) return "-";
  const startTime = moment(start).format('DD/MM/YYYY HH:mm:ss')
  const endTime = moment(end).format('DD/MM/YYYY HH:mm:ss')
  let duration = moment.utc(moment(endTime, 'DD/MM/YYYY HH:mm:ss').diff(moment(startTime, 'DD/MM/YYYY HH:mm:ss'))).format('HH:mm:ss')
  let duration_days = moment(endTime, 'DD/MM/YYYY HH:mm:ss').diff(moment(startTime, 'DD/MM/YYYY HH:mm:ss'), "days")
  if(duration_days > 0) {
    return (`${duration_days}D ${duration}`);
  } else {
    return (duration);
  }
}

export const diffDays = (start, end) => {
  const utc1 = moment(start).utc()
  const utc2 = moment(end).utc()

  return utc2.diff(utc1, 'days');
}

export const defaultDateRange = (value = moment()) => {
  const fromDate = moment(value).startOf('month').format('YYYY-MM-DD')
  const toDate = moment(value).endOf('month').format('YYYY-MM-DD')

  return { fromDate, toDate }
}

export const formattingDate = (value, reqFormat = 'YYYY-MM-DD') => {
  return moment(value).format(reqFormat)
}

export const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const parseTimestampToGMT = (timestampStr) => {
  return new Date(
    new Date(timestampStr).getTime() + new Date(timestampStr).getTimezoneOffset() * 60 * 1000,
  )
}

export const getMenuPermission = (menuCd, menuTree) => {
  let add_flag = null
  let del_flag = null
  let edit_flag = null

  for (var k in menuTree) {
    if (menuTree[k].cd == menuCd) {
      add_flag = menuTree[k].add_flag
      del_flag = menuTree[k].del_flag
      edit_flag = menuTree[k].edit_flag
    } else {
      for (var key in menuTree[k].child) {
        if (menuTree[k].child[key].cd == menuCd) {
          add_flag = menuTree[k].child[key].add_flag
          del_flag = menuTree[k].child[key].del_flag
          edit_flag = menuTree[k].child[key].edit_flag
        } else {
          for (var key2 in menuTree[k].child[key].child) {
            if (menuTree[k].child[key].child[key2].cd == menuCd) {
              add_flag = menuTree[k].child[key].child[key2].add_flag
              del_flag = menuTree[k].child[key].child[key2].del_flag
              edit_flag = menuTree[k].child[key].child[key2].edit_flag
            }
          }
        }
      }
    }
  }

  return { add_flag: add_flag, del_flag: del_flag, edit_flag: edit_flag }
}

export const inputDecimal = (value) => {
  const pattern = /^\d*\.?\d*$/
  if (value !== '' && pattern.test(value)) {
    return value
  }
  return ''
}

// LANGUAGE
export const getLanguage = (key) => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(key) : ''

  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}

export const setLanguage = (key, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value
  return localStorage.setItem(key, stringify)
}

export const validateOriginUrlCallback = (callback) => {
  const whiteListUrl = process.env.REACT_APP_WHITELIST_URL.split(',')
  const urlOrigin = window.location.origin

  if (!whiteListUrl.includes(urlOrigin)) {
    callback({ isError: 'Y', message: 'Unable to Reach' })
    return false
  }
  return true
}

export const getAllDaysInMonth = (year = null, month = null) => {
  const now = new Date();
  // if year & month null
  if (year === null && month === null) {
    year = now.getFullYear()
    month = now.getMonth()
  } else if (year === null && month !== null) {
    year = now.getFullYear()
  } else if (year !== null && month === null) {
    month = now.getMonth()
  }

  const date = new Date(year, month, 1)
  const dates = []
  while (date.getMonth() === month) {
    dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return dates
}

export const handleThousandSeparator = (e) => {
  const { value } = e.target;

  if (value === "0" || !value) return ""

  const parsed = typeof value === "string" || value instanceof String ? value : value.toString();

  let number_string = parsed.replace(/[^,\d]/g, ""),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;

  let rupiah_unmasked = number_string.charAt(number_string.length - 1) === "," ? number_string.replace(",", "") : number_string.replace(",", ".")

  return { rupiah, rupiah_unmasked };
}

export const allowedText = (value, options) => {
  let text = value
  let optionsText = {
    lower_case: options.lower_case ?? false,
    upper_case: options.upper_case ?? false,
    stricted_symbol: options.stricted_symbol ?? null,
    space_convert: options.space_convert ?? null,
  }

  if (optionsText.lower_case) {
    text = text.toLowerCase()
  }

  if (optionsText.upper_case) {
    text = text.toUpperCase()
  }

  if (optionsText.stricted_symbol) {
    let regex = "";
    let conditions = optionsText.stricted_symbol;
    if (conditions !== true) {
      regex = `[^a-zA-Z0-9 ${conditions?.length && conditions}${optionsText.space_convert !== null ? optionsText.space_convert : ""}]`;
    } else {
      regex = `[^a-zA-Z0-9 ${optionsText.space_convert !== null ? optionsText.space_convert : ""}]`;
    }
    regex = new RegExp(regex);
    console.log(regex);
    text = text.replace(regex, "");
  }

  if (optionsText.space_convert === '') {
    text = text.replace(/\s/g, '')
  } else if (optionsText.space_convert) {
    text = text.replace(/\s/g, optionsText.space_convert)
  }

  return text
}

export const convertApprovalStatus = (statusCd) => {
  let desc = ''
  let color = ''
  switch (statusCd) {
    case 'PENDING':
        color = '#ffc000'
        desc = 'Menunggu Persetujuan'
      break
    case 'APPROVE':
        color = '#99ff99'
        desc = 'Disetujui'
      break
    case 'REJECT':
        color = '#f02c2c'
        desc = 'Ditolak'
      break
  
    default:
      break
  }
  
  return { desc: desc, color: color }
}

export const API_BASEURL = () => {
  const API_BASE = (window.location.origin === process.env?.REACT_APP_HOST_DEV) ? 'http://127.0.0.1:5000' : window.location.origin
  return API_BASE
}