import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'

import { getStore, removeItem } from '../helpers/HelperGlobal'
import ApiService from 'src/services/ApiService'

const useLogout = () => {
  const navigate = useNavigate()

  const requestLogout = async () => {
    const account = getStore('userAccount')
    if (account !== null) {
      const payload = {
        userid: account.username,
        password: account.password,
      }

      ApiService.apiPostJson(
        '/loadminlogout',
        payload,
        (data) => {
          const url =
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '')
          if (data.is_error === 'N') {
            removeItem('userAccount')
            Cookies.remove('credentialToken')
            window.location.href = url
          }
        },
        (e) => {
          alert(e.responseJSON.message)
          navigate('/')
        }
      )
    }
  }

  return { requestLogout }
}

export default useLogout
