import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import NavigationRedux from './redux/NavigationRedux'
import AccountRedux from './redux/AccountRedux'

const rootReducer = combineReducers({
  changeState: NavigationRedux,
  account: AccountRedux,
})

const configureStore = () => {
  return createStore(rootReducer, compose(applyMiddleware(thunk)))
}

export default configureStore
