import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18nextHttpBackend from 'i18next-http-backend'
import { getLanguage } from './HelperGlobal'
const lng = getLanguage('language') ? getLanguage('language') : 'id'
i18n
.use(i18nextHttpBackend)
.use(initReactI18next).init({
  debug: false,
  lng: lng,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  },   
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
