import $ from "jquery"

const API_BASE_GLOBAL = 'https://oppo.dickyri.net'

const ApiService = {
    API_BASE: API_BASE_GLOBAL,
    apiPostJson: async (path, payload, onSuccess, onError) => {
        const url = API_BASE_GLOBAL + path
        try {
            const response = await $.ajax({
                type: "POST",
                url,
                data: payload,
                dataType: "json"
            });
            onSuccess(response);
        } catch (e) {
            onError(e);
        }

        // await $.ajax({
        //     type: "POST",
        //     url,
        //     data: payload,
        //     dataType: "json",
        //     success(data) {
        //         onSuccess(data)
        //     },
        //     error(e) {
        //         onError(e)
        //     }
        // });
    },
    apiPostFile: async (path, payload, onSuccess, onError) => {
        const url = API_BASE_GLOBAL + path

        try {
            const response = await $.ajax({
                type: "POST",
                url,
                data: payload,
                dataType: "json",
                processData: false,
                contentType: false,
            })
            onSuccess(response);
        } catch (e) {
            onError(e);
        }
    }
}

export default ApiService