import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import useLogout from '../../hooks/useLogout'
import { getStore } from 'src/helpers/HelperGlobal'

const ProtectedRoute = ({ children }) => {
  const { requestLogout } = useLogout()

  const isLoggedIn = () => {
    let isLoggedIn = null
    if (Cookies.get('credentialToken') !== undefined && getStore('userAccount')) {
      isLoggedIn = Cookies.get('credentialToken')
    } else {
      requestLogout()
    }
    return isLoggedIn
  }

  return isLoggedIn() ? children : <Navigate to="/login" />
}

ProtectedRoute.propTypes = {
  children: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    account: state.account.profile,
  }
}

export default connect(mapStateToProps)(ProtectedRoute)
