import 'react-app-polyfill/stable'
import 'core-js'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './helpers/I18n'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './store'
const store = configureStore()

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <Suspense fallback={<h1>Loading...</h1>}>
      <App />
    </Suspense>
  </Provider>,
)

serviceWorker.unregister()
